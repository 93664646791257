.formDescription {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-33xl);
  line-height: 4.625rem;
  font-weight: 700;
  font-family: var(--font-poppins);
  color: var(--color-darkslategray-200);
  text-align: left;
}
.contactBody {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  min-width: 24.563rem;
  max-width: 100%;
}
.imageIcon {
  width: 34.813rem;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.contactUsDetail,
.contactUsDetailBody {
  align-self: stretch;
  background-color: var(--color-white);
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.contactUsDetailBody {
  box-shadow: 0 0 2px rgba(23, 26, 31, 0.12), 0 4px 9px rgba(23, 26, 31, 0.11);
  border-radius: var(--br-xl);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-mini);
}
.contactUsDetail {
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-77xl) var(--padding-113xl) var(--padding-7xl);
  box-sizing: border-box;
}
@media screen and (max-width: 1225px) {
  .imageIcon {
    flex: 1;
  }
  .contactUsDetailBody {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .formDescription {
    font-size: var(--font-size-23xl);
    line-height: 3.688rem;
  }
  .contactBody {
    min-width: 100%;
  }
  .contactUsDetail {
    padding: var(--padding-43xl) var(--padding-47xl) var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .formDescription {
    font-size: var(--font-size-12xl);
    line-height: 2.75rem;
  }
  .contactUsDetail {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
