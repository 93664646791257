@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-open-sans: "Open Sans";
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-xl: 1.25rem;
  --font-size-lg: 1.125rem;
  --font-size-33xl: 3.25rem;
  --font-size-12xl: 1.938rem;
  --font-size-23xl: 2.625rem;
  --font-size-5xl: 1.5rem;
  --font-size-lgi: 1.188rem;
  --font-size-29xl: 3rem;
  --font-size-10xl: 1.813rem;
  --font-size-19xl: 2.375rem;

  /* Colors */
  --color-white: #fff;
  --color-royalblue: #1e64dd;
  --color-dodgerblue: #458aff;
  --color-lightslategray: #9095a1;
  --color-silver: #bdc1ca;
  --color-darkslategray-100: #424856;
  --color-darkslategray-200: #323743;
  --color-whitesmoke: #f8f9fa;
  --color-gray-100: #171a1f;
  --color-gray-200: rgba(0, 0, 0, 0);
  --color-slategray: #6f7787;

  /* Gaps */
  --gap-3xs: 0.625rem;
  --gap-xl: 1.25rem;
  --gap-base: 1rem;
  --gap-lg: 1.125rem;
  --gap-mini: 0.937rem;
  --gap-12xs: 0.062rem;
  --gap-29xl: 3rem;
  --gap-5xl: 1.5rem;
  --gap-xs: 0.75rem;
  --gap-17xl: 2.25rem;
  --gap-45xl: 4rem;
  --gap-13xl: 2rem;
  --gap-0: 0rem;
  --gap-mid: 1.062rem;
  --gap-7xs: 0.375rem;
  --gap-78xl: 6.062rem;
  --gap-20xl: 2.437rem;
  --gap-lgi: 1.187rem;

  /* Paddings */
  --padding-12xl: 1.937rem;
  --padding-113xl: 8.25rem;
  --padding-xl: 1.25rem;
  --padding-47xl: 4.125rem;
  --padding-3xs: 0.625rem;
  --padding-5xs: 0.5rem;
  --padding-163xl: 11.375rem;
  --padding-77xl: 6rem;
  --padding-7xl: 1.625rem;
  --padding-43xl: 3.875rem;
  --padding-xs: 0.75rem;
  --padding-7xs: 0.375rem;
  --padding-mini: 0.937rem;
  --padding-sm-5: 0.843rem;
  --padding-10xs: 0.187rem;
  --padding-66xl: 5.312rem;
  --padding-4xl: 1.437rem;
  --padding-17xl: 2.25rem;
  --padding-81xl: 6.25rem;
  --padding-31xl: 3.125rem;
  --padding-46xl: 4.062rem;
  --padding-6xl: 1.562rem;
  --padding-4xs: 0.562rem;
  --padding-309xl: 20.5rem;
  --padding-45xl: 4rem;
  --padding-23xl: 2.625rem;

  /* Border radiuses */
  --br-xl: 20px;
  --br-7xs: 6px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-3xs: 10px;
}
