.text {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 4.625rem;
  font-weight: 700;
  font-family: inherit;
}
.image22Icon {
  height: 1.75rem;
  width: 1.75rem;
  position: relative;
  object-fit: cover;
  min-height: 1.75rem;
}
.detailItems {
  position: relative;
  line-height: 1.75rem;
}
.image23Icon {
  height: 1.75rem;
  width: 1.75rem;
  position: relative;
  object-fit: cover;
  min-height: 1.75rem;
}
.email {
  position: relative;
  line-height: 1.75rem;
  white-space: nowrap;
}
.image24Icon {
  height: 1.75rem;
  width: 1.75rem;
  position: relative;
  object-fit: cover;
  min-height: 1.75rem;
}
.number {
  position: relative;
  line-height: 1.75rem;
  display: inline-block;
  min-width: 6.5rem;
}
.contactUsBody1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-sm-5);
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray-200);
  font-family: var(--font-open-sans);
}
.contactUsBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-29xl);
  width: 100%;
  text-align: center;
  font-size: var(--font-size-33xl);
  color: var(--color-royalblue);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 768px) {
  .text {
    font-size: var(--font-size-23xl);
    line-height: 3.688rem;
  }
  .contactUsBody1 {
    flex-wrap: wrap;
  }
  .contactUsBody {
    gap: var(--gap-5xl);
  }
}
@media screen and (max-width: 450px) {
  .text {
    font-size: var(--font-size-12xl);
    line-height: 2.75rem;
  }
  .contactUsBody1 {
    flex-direction: column;
  }
}
