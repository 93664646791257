.vacancyDescription,
.vacancyTitle {
  position: relative;
  display: inline-block;
}
.vacancyTitle {
  line-height: 1.375rem;
  min-width: 3.188rem;
}
.vacancyDescription {
  margin: 0;
  font-size: var(--font-size-33xl);
  line-height: 4.625rem;
  font-weight: 700;
  font-family: var(--font-poppins);
  max-width: 100%;
}
.vacancyDescription1 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 1.875rem;
  color: var(--color-darkslategray-200);
  text-align: left;
}
.vacancyContent1 {
  width: 34.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  min-width: 34.875rem;
  max-width: 100%;
}
.imageIcon,
.imageIcon1 {
  max-width: 100%;
  overflow: hidden;
}
.imageIcon {
  /* height: 23.894rem; */
  flex: 1;
  position: relative;
  object-fit: cover;
  min-width: 24.375rem;
}
.imageIcon1 {
  height: 20.59%;
  width: 12.76%;
  position: absolute;
  margin: 0 !important;
  top: 55.58%;
  right: 41.92%;
  bottom: 23.83%;
  left: 45.32%;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.vacancy,
.vacancyContent {
  align-self: stretch;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.vacancyContent {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-10xs);
  position: relative;
  gap: var(--gap-xs);
}
.vacancy {
  background-color: var(--color-whitesmoke);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-77xl) var(--padding-113xl);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-royalblue);
  font-family: var(--font-open-sans);
}
@media screen and (max-width: 1225px) {
  .vacancyContent1 {
    flex: 1;
  }
  .vacancyContent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1050px) {
  .vacancyContent1 {
    min-width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .vacancyDescription {
    font-size: var(--font-size-23xl);
    line-height: 3.688rem;
  }
  .imageIcon {
    min-width: 100%;
  }
  .vacancy {
    padding: var(--padding-43xl) var(--padding-47xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .vacancyDescription {
    font-size: var(--font-size-12xl);
    line-height: 2.75rem;
  }
  .vacancyDescription1 {
    font-size: var(--font-size-base);
    line-height: 1.5rem;
  }
  .vacancy {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
