.featuresHeader {
  margin: 0;
  width: 73.5rem;
  position: relative;
  font-size: inherit;
  line-height: 4.25rem;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.featuresBody {
  width: 73.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap-45xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray-200);
}
.features,
.featuresBody,
.featuresBody1 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.featuresBody1 {
  width: 73.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap-45xl);
}
.features {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--color-white);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-77xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-royalblue);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1225px) {
  .featuresBody,
  .featuresBody1 {
    gap: var(--gap-13xl);
  }
}
@media screen and (max-width: 750px) {
  .featuresHeader {
    font-size: var(--font-size-19xl);
    line-height: 3.375rem;
  }
  .featuresBody,
  .featuresBody1 {
    gap: var(--gap-base);
  }
  .features {
    gap: var(--gap-lg);
    padding-top: var(--padding-43xl);
    padding-bottom: var(--padding-43xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .featuresHeader {
    font-size: var(--font-size-10xl);
    line-height: 2.563rem;
  }
}
