@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.logoIcon {
  height: 3.163rem;
  width: 10.125rem;
  position: relative;
  object-fit: contain;
}
.text {
  min-width: 4rem;
  white-space: nowrap;
}
.text,
.text1,
.text2,
.text3 {
  position: relative;
  line-height: 1.375rem;
  display: inline-block;
  text-decoration: none;
}
.text1 {
  min-width: 5rem;
  white-space: nowrap;
}
.text2,
.text3 {
  min-width: 3.188rem;
}
.text3 {
  min-width: 4.438rem;
  white-space: nowrap;
}
.links {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
}
.headerMenuMain {
  width: 23.938rem;
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-20xl);
  max-width: 100%;
}
.header,
.navigation {
  align-self: stretch;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.navigation {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-xs) 0;
  gap: var(--gap-xl);
}
.header {
  box-shadow: 0 0 2px rgba(23, 26, 31, 0.12), 0 2px 5px rgba(23, 26, 31, 0.09);
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-81xl);
  top: 0;
  z-index: 99;
  position: sticky;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-100);
  font-family: var(--font-open-sans);
  width: 100%;
}
.links a {
  text-decoration: none;
  color: var(--color-darkslategray-200);
}
.mobilenav{
  display: none;
}
@media screen and (max-width: 750px) {
  .links {
    display: none;
  }
  .headerMenuMain {
    width: 0;
  }
  .header {
    gap: var(--gap-78xl);
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .links {
    display: none;
  }
  .mobilenav {
    display: flex;
    position: relative;
  }
  .button{
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .mobilenav{
    display: flex;
    position: relative;
    right: 1rem;
  }
  .button{
    display: none;
  }
}
