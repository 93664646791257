@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.text {
  line-height: 1.375rem;
  color: var(--color-royalblue);
}
.heroDescription,
.text,
.text1 {
  align-self: stretch;
  position: relative;
}
.text1 {
  margin: 0;
  font-size: var(--font-size-33xl);
  line-height: 4.625rem;
  font-weight: 700;
  font-family: var(--font-poppins);
}
.heroDescription {
  font-size: var(--font-size-xl);
  line-height: 1.875rem;
}
.heroBody {
  width: 37.625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  min-width: 37.625rem;
  max-width: 100%;
}
.heroBody.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-left;
}
.imageIcon {
  width: 34.875rem;
  position: relative;
  border-radius: var(--br-3xs);
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.heroContent,
.heroSection {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.heroContent {
  width: 73.5rem;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 20px;
  gap: var(--gap-0);
}
.heroSection {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
  font-family: var(--font-open-sans);
}

.video {
  width: 500px;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1225px) {
  .heroBody,
  .imageIcon {
    flex: 1;
  }
  .heroContent {
    flex-wrap: wrap;
  }
  .video {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 750px) {
  .text1 {
    font-size: var(--font-size-23xl);
    line-height: 3.688rem;
  }
  .heroBody {
    min-width: 100%;
  }
  .heroSection {
    padding-top: var(--padding-23xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
  .video {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 450px) {
  .text1 {
    font-size: var(--font-size-12xl);
    line-height: 2.75rem;
  }
  .heroDescription {
    font-size: var(--font-size-base);
    line-height: 1.5rem;
  }
  .video {
    width: 100%;
    height: auto;
  }
}
