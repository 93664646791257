.imageIcon {
  height: 48.83%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  bottom: 51.17%;
  left: 1rem;
  max-height: 100%;
  object-fit: contain;
}
.contactUs,
.imageIcon,
.imageIcon1 {
  overflow: hidden;
  max-width: 100%;
}
.imageIcon1 {
  /* width: 17.71%; */
  height: 71.35%;
  position: absolute;
  margin: 0 !important;
  top: 28.65%;
  right: 1rem;
  bottom: 0;
  max-height: 100%;
  object-fit: contain;
}
.contactUs {
  align-self: stretch;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-29xl);
  /* min-height: 21.375rem; */
  text-align: center;
  font-size: var(--font-size-33xl);
  color: var(--color-royalblue);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 960px) {
  .contactUs {
    gap: var(--gap-5xl);
  }
  .imageIcon{
    display: none;
  }
  .imageIcon1{  
    display: none;
  }
}
