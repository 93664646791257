.blockDescriptions,
.text {
  align-self: stretch;
  position: relative;
}
.text {
  line-height: 1.625rem;
  text-transform: uppercase;
}
.blockDescriptions {
  margin: 0;
  font-size: var(--font-size-29xl);
  line-height: 4.25rem;
  font-weight: 700;
  font-family: var(--font-poppins);
  color: var(--color-gray-100);
}
.blockDetails,
.buttonLabels {
  position: relative;
  line-height: 1.625rem;
}
.blockDetails {
  align-self: stretch;
}
.buttonLabels {
  display: inline-block;
  min-width: 5.438rem;
}
.imageIcon {
  height: 1.25rem;
  width: 1.25rem;
  position: relative;
  object-fit: contain;
}
.bodyContent,
.button {
  display: flex;
  align-items: center;
}
.button {
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-309xl) var(--padding-4xs) 0;
  gap: var(--gap-7xs);
  white-space: nowrap;
  color: var(--color-royalblue);
}
.bodyContent {
  width: 33.813rem;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-31xl);
  box-sizing: border-box;
  gap: var(--gap-mid);
  min-width: 33.813rem;
  max-width: 100%;
}
.image21Icon {
  width: 39.688rem;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.bodySection {
  width: 73.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;
  max-width: 100%;
  gap: var(--gap-0);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-slategray);
  font-family: var(--font-open-sans);
}
@media screen and (max-width: 1225px) {
  .bodyContent,
  .image21Icon {
    flex: 1;
  }
  .bodySection {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .blockDescriptions {
    font-size: var(--font-size-19xl);
    line-height: 3.375rem;
  }
  .bodyContent {
    padding: var(--padding-46xl) var(--padding-6xl);
    box-sizing: border-box;
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .blockDescriptions {
    font-size: var(--font-size-10xl);
    line-height: 2.563rem;
  }
  .button {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
