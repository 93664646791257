.text {
  position: relative;
  line-height: 1.375rem;
  display: inline-block;
  text-decoration: none;
}
.button, .homebutton {
  border-radius: var(--br-7xs);
  background-color: var(--color-royalblue);
  overflow: hidden;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-open-sans);
  border: none;
}
@media screen and (max-width: 960px) {
  
  a .button{
    display: none;
  }
}
@media screen and (max-width: 420px) {
  
  a .button{
    display: none;
  }
}