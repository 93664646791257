.multiplaiLandingPage {
  width: 100%;
  position: relative;
  box-shadow: 0 3px 6px rgba(18, 15, 40, 0.12);
  background-color: var(--color-white);
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 0 0 2.631rem; */
  box-sizing: border-box;
  line-height: normal;
  letter-spacing: normal;
}
.scroll {
  background-color: var(--color-royalblue) !important;
  color: white !important;
  width: 40px !important;
  height: 40px !important;
  /* border-radius: 40px !important; */
}