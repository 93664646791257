.image22Icon {
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  object-fit: contain;
}
.featureDetails,
.featureTitles {
  align-self: stretch;
  position: relative;
}
.featureTitles {
  margin: 0;
  font-size: inherit;
  line-height: 2.25rem;
  font-weight: 700;
  font-family: inherit;
}
.featureDetails {
  font-size: var(--font-size-xl);
  line-height: 1.875rem;
  font-family: var(--font-open-sans);
}
.featureList {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  min-width: 22.563rem;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 450px) {
  .featureTitles {
    font-size: var(--font-size-lgi);
    line-height: 1.813rem;
  }
  .featureDetails {
    font-size: var(--font-size-base);
    line-height: 1.5rem;
  }
  .featureList {
    min-width: 100%;
  }
}
