.ourWork {
  margin: 0;
  width: 73.5rem;
  position: relative;
  font-size: var(--font-size-29xl);
  line-height: 4.25rem;
  font-weight: 700;
  font-family: var(--font-poppins);
  color: var(--color-royalblue);
  text-align: center;
  display: inline-block;
  max-width: 100%;
}
.body {
  align-self: stretch;
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-66xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-45xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-slategray);
  font-family: var(--font-open-sans);
}
@media screen and (max-width: 1225px) {
  .body {
    padding-top: var(--padding-17xl);
    padding-bottom: var(--padding-17xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .ourWork {
    font-size: var(--font-size-19xl);
    line-height: 3.375rem;
  }
  .body {
    gap: var(--gap-13xl);
    padding-top: var(--padding-4xl);
    padding-bottom: var(--padding-4xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .ourWork {
    font-size: var(--font-size-10xl);
    line-height: 2.563rem;
  }
  .body {
    gap: var(--gap-base);
  }
}
