.label,
.placeholder {
  position: relative;
  line-height: 1.625rem;
}
.label {
  align-self: stretch;
}
.placeholder {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: var(--font-open-sans);
  font-size: var(--font-size-base);
  background-color: transparent;
  height: 1.625rem;
  flex: 1;
  color: var(--color-darkslategray-200);
  text-align: left;
  display: inline-block;
  min-width: 15.625rem;
  max-width: 100%;
  padding: 0;
}
.textField,
.textfield {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.textfield {
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-lightslategray);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-mini);
}
.textField {
  flex-direction: column;
  gap: var(--gap-12xs);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-darkslategray-100);
  font-family: var(--font-open-sans);
}
textarea{
 height: 100px !important;
}