.logoWhiteIcon {
  width: 8.75rem;
  height: 2.881rem;
  position: relative;
  object-fit: contain;
}
.companyDescription {
  position: relative;
  line-height: 1.625rem;
}
.widget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.linksHeader {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 1.875rem;
}
.linkCollection,
.linkCollection1,
.linkCollection2,
.linkCollection3 {
  align-self: stretch;
  position: relative;
  line-height: 1.625rem;
  display: inline-block;
  text-decoration: none;
  color:white;
}
.widget1 {
  width: 17.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.socialHeader {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 1.875rem;
}
.icon,
.icon1,
.icon2 {
  height: 1.531rem;
  width: 1.5rem;
  position: relative;
  object-fit: cover;
  min-height: 1.563rem;
}
.footerIcons {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-163xl) 0 0;
  gap: var(--gap-base);
}
.socialLinks,
.socialLinks1 {
  align-self: stretch;
  position: relative;
  line-height: 1.625rem;
}
.socialLinks1 {
  white-space: nowrap;
}
.footerBodyContent,
.widget2 {
  display: flex;
  align-items: flex-start;
}
.widget2 {
  width: 17.875rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.footerBodyContent {
  align-self: stretch;
  border-bottom: 1px solid var(--color-white);
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-3xs) 0 var(--padding-5xs);
  gap: var(--gap-xl);
}
.text {
  flex: 1;
  position: relative;
  line-height: 1.375rem;
  display: inline-block;
  max-width: 100%;
}
.copyright,
.footer,
.footerBody {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.copyright {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
}
.footer,
.footerBody {
  flex-direction: column;
  align-items: flex-start;
}
.footerBody {
  justify-content: center;
  gap: var(--gap-3xs);
}
.footer {
  background-color: var(--color-royalblue);
  justify-content: flex-start;
  padding: var(--padding-12xl) var(--padding-113xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-open-sans);
}
@media screen and (max-width: 1225px) {
  .footerBodyContent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .footer {
    padding-left: var(--padding-47xl);
    padding-right: var(--padding-47xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .linksHeader,
  .socialHeader {
    font-size: var(--font-size-base);
    line-height: 1.5rem;
  }
  .footer,
  .footerIcons {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .footer {
    padding-left: var(--padding-xl);
  }
}
